import {inject} from '@angular/core'
import {Observable, of} from 'rxjs'
import {environment} from '../../environments/environment'
import {WINDOW} from './window.provider'

export const deadGuard = (): Observable<boolean | Promise<boolean>> => {
  const window = inject(WINDOW)

  window.location.href =  environment.adminServiceUrl
  return of(false)
}