import {registerLocaleData} from '@angular/common'
import {provideHttpClient} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {SpbCommonModule} from './common/common.module'
import {LoanService} from './services/loan.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpbCommonModule,
    ThemeModule], providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    LoanService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: LoanService) => () => service.isLoggedIn(),
      deps: [LoanService],
      multi: true
    },
    provideHttpClient()
  ]
})
export class AppModule {
}
